<template>
  <div class="signReset">
    <div class="bg"></div>
    <div class="sign-bg">
      <div class="sign-main">
        <img
          src="@/assets/sign-a.png"
          class="sign-a pointer"
          @click="toUrl('/index')"
        />
        <div class="sign-form">
          <div class="forms">
            <div class="forms-tit">重置密码</div>
            <div class="input-div">
              <input v-model="mobile" placeholder="请输入注册手机号" />
            </div>
            <div class="input-div2 flex between">
              <div class="input-div3">
                <input v-model="verification_code" placeholder="验证码" />
              </div>
              <div class="code-div pointer" @click="getCode" v-if="isShow">
                获取验证码
              </div>
              <div class="code-div code-div2" v-else>
                <span>{{ count }}</span> 秒后重新获取
              </div>
            </div>
            <div class="input-div">
              <input
                v-model="password"
                type="password"
                placeholder="设置密码"
              />
            </div>
            <div class="input-div">
              <input
                v-model="repassword"
                type="password"
                placeholder="确认密码"
              />
            </div>
            <div class="tips">
              想起了密码，<span class="pointer" @click="toUrl('/signLogin')"
                >去登录</span
              >
            </div>
            <div class="login-btn pointer" @click="toSubmit">提交</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { sendEditPasswordCode, changePassword } from "@/api/apiData";
export default {
  data() {
    return {
      mobile: "",
      verification_code: "", //短信验证码
      password: "",
      repassword: "",
      isShow: true, //通过 v-show 控制显示'获取按钮'还是'倒计时'
      count: 0, //倒计时 计数器
      msg: "", //如果msg为空是蓝色，点击之后变灰色
    };
  },
  mounted() {
    this.$bus.emit("navS", 0);
  },
  methods: {
    toUrl(u) {
      this.$router.push(u);
    },
    // 获取验证码
    getCode() {
      if (this.mobile == "") {
        this.$message.error("请输入手机号");
      } else {
        sendEditPasswordCode({ phone: this.mobile }).then((res) => {
          if (res.code == 1) {
            this.isShow = false;
            this.count = 60; //赋值3秒
            var times = setInterval(() => {
              this.count--; //递减
              if (this.count <= 0) {
                // <=0 变成获取按钮
                this.isShow = true;
                clearInterval(times);
              }
            }, 1000); //1000毫秒后执行
            this.$message({
              type: "success",
              message: res.msg,
            });
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
    // 提交
    toSubmit() {
      let obj = {
        mobile: this.mobile,
        verification_code: this.verification_code,
        password: this.password,
        repassword: this.repassword,
      };
      if (obj.mobile == "") {
        this.$message.error("请输入手机号");
      } else if (obj.verification_code == "") {
        this.$message.error("请输入验证码");
      } else if (obj.password == "") {
        this.$message.error("请输入密码");
      } else if (obj.repassword == "") {
        this.$message.error("请输入确认密码");
      } else {
        this.$confirm("确定要提交吗？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            changePassword(obj).then((res) => {
              if (res.code == 1) {
                this.$message({
                  type: "success",
                  message: res.msg,
                });
                localStorage.removeItem("token");
                this.$router.push("/signLogin");
              } else {
                this.$message.error(res.msg);
              }
            });
          })
          .catch(() => {});
      }
    },
  },
};
</script>
<style lang="less" scoped>
.bg {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #539fea;
  z-index: -1;
}
.sign-bg {
  width: 100%;
  .sign-main {
    width: 1073px;
    margin: 80px auto;
    .sign-a {
      width: 50%;
      margin-bottom: 30px;
    }
    .sign-form {
      width: 100%;
      background: white;
      padding: 50px 0;
      .forms {
        width: 40%;
        margin: 0 auto;
        .forms-tit {
          font-size: 21px;
          text-align: center;
        }
        .input-div {
          padding: 15px;
          border-bottom: solid 1px #d1d1d1;
          margin-top: 30px;
        }
        .input-div2 {
          padding: 10px 0;
          margin-top: 30px;
          .input-div3 {
            width: 70%;
            border-bottom: solid 1px #d1d1d1;
            padding-bottom: 15px;
            padding-left: 15px;
          }
          .code-div {
            background: #0089e1;
            width: 30%;
            line-height: 37px;
            font-size: 14px;
            text-align: center;
            color: white;
          }
          .code-div2 {
            background: #3da4e4;
          }
        }
        input {
          border: none;
          font-size: 16px;
        }
        .tips {
          text-align: right;
          font-size: 14px;
          color: #c4c4c4;
          margin: 20px 0 30px;
          span {
            color: #0089e1;
          }
        }
        .login-btn {
          width: 100%;
          background: #0089e1;
          color: white;
          font-size: 20px;
          text-align: center;
          line-height: 49px;
          border-radius: 4px;
          margin: 40px 0 20px;
        }
      }
    }
  }
}
</style>